@import "../variables.scss";

.backgroundImageFull {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.backgroundImageSemi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}
