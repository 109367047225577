@import "../variables.scss";

.headerTextStyle {
  font-size: 2rem;
  margin-top: 66px;
  color: $light;
  font-family: "Avenir-DemiBold";
}
.logoStyle {
  width: 300px;
  height: auto;
}
.subTitleText {
  font-family: "Avenir-Medium";
  font-size: 1.375rem;
  color: $light;
  margin-top: 8px;
}

.innerTextContainer {
  text-align: left;
  line-height: 2.5em;
}

.text18 {
  color: $light;
  font-size: 1.125rem;
  font-family: "Avenir-Medium";
  text-align: left;
  margin-left: 46px;
}
.text22 {
  color: $light;
  font-size: 1.375rem;
  font-family: "Avenir-Medium";
  text-align: left;
}

.blueCheck {
  margin-right: 8px;
  position: relative;
  top: 5px;
}
.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 15px;
}
