@import "../variables.scss";

.errorText {
  font-size: 1.375rem;
  color: $light;
  margin-top: 4px;
  font-family: "Avenir-MediumItalic";
  height: 1em;
}

.animateIt {
  color: $warning;
  animation-name: fadeToWhite;
  animation-duration: 0.5s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
}

@keyframes fadeToWhite {
  from {
    color: $warning;
  }
  to {
    color: $light;
  }
}
