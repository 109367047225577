@import "../variables.scss";

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerTextContainer {
  text-align: left;
  line-height: 2.5em;
}

.text {
  position: relative;
  color: $light;
  font-size: 1.375rem;
  font-family: "Avenir-Medium";
  text-align: center;
  margin-bottom: 4px;
  margin-top: -14px;
}
